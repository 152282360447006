<template>
  <!-- start 自定义表格对话框 -->
  <el-dialog
    title="设置表格"
    :visible.sync="showDialog"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <template v-for="(item, index) in headTops">
        <!-- start 一级表头(全选框) -->
        <div class="title">
          <el-checkbox
            v-model="item.checkAll"
            :indeterminate="item.isIndeterminate"
            @change="onChangeCheckAll($event, index)"
          >
            {{ item.Name }}
          </el-checkbox>
        </div>
        <!-- end 一级表头(全选框) -->

        <!-- start 二级子表头  -->
        <div class="col">
          <template v-for="city in heads">
            <el-checkbox
              v-if="item.Code == city.TopCode"
              v-model="city.Visible"
              :key="city.DataKey"
              :true-label="1"
              :false-label="0"
              @change="onChangeCheckbox($event, index)"
            >
              {{ city.Name }}
            </el-checkbox>
          </template>
        </div>
        <!-- end 二级子表头  -->
      </template>
    </div>

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCloseDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="onSubmitData"
        >确 定</el-button
      >
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 自定义表格对话框 -->
</template>

<script>
export default {
  data() {
    return {
      headTops: [], // 一级表头
      heads: [], // 二级表头
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 多选框点击事件（初始化表头被选中状态）
    onChangeCheckbox() {
      // 由于这整个组件是从前任前端基础上抽成出来修改的，原本是依靠点击事件传的index来更改多选框状态的，
      // 我想着太麻烦了且他有一些细节没做好，导致初始化的全选框状态以及点击更改事件不精确，我这里就写的粗糙一点
      // 性能不一定最高效，但解决了细节问题，并方便下一任看的清晰一点
      this.headTops.forEach((tops) => {
        // 选中的表头
        let selected = this.heads.filter(
          (heads) => heads.TopCode == tops.Code && heads.Visible == 0
        );

        // 没选中的表头
        let notSelected = this.heads.filter(
          (heads) => heads.TopCode == tops.Code && heads.Visible == 1
        );

        // 这里checkAll是v-model要绑定的状态，是一个打钩的状态，indeterminate是一条杠状态，是不一样的，
        // 需不需要indeterminate要看需求，但是原本前任是有做的，虽然有点bug但我这里就保留了，不需要可以去掉
        tops.checkAll = selected.length == 0 ? true : false; // 全选框checkAll状态
        tops.isIndeterminate =
          notSelected.length == 0 || tops.checkAll ? false : true; // 全选框indeterminate状态
      });
    },

    // 全选框点击事件
    onChangeCheckAll(event, index) {
      this.headTops[index].isIndeterminate = false;
      this.headTops[index].checkAll = event;
      this.heads.filter((item) => {
        if (item.TopCode == this.headTops[index].Code) {
          item.Visible = event ? 1 : 0;
        }
      });
    },

    // 打开对话框
    async onShowDialog(data) {
      let { HeadTops, Heads } = JSON.parse(JSON.stringify(data));
      this.headTops = HeadTops;
      this.heads = Heads;
      this.onChangeCheckbox();
      this.showDialog = true;
    },

    // 重置多选框表头数据
    resetCustomData() {
      this.heads = [];
      this.headTops = [];
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },

    // 确定按钮点击事件
    async onSubmitData() {
      this.$emit("onConfirm", { HeadTops: this.headTops, Heads: this.heads });
      this.onCloseDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-top: 20px;
  margin-bottom: 10px;

  ::v-deep .el-checkbox__label {
    color: #000000;
    font-weight: bold;
  }
}
</style>